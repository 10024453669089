import "./detailsListStyles.scss";
import CarCheck from "./car-check.png";
import Faucet from "./faucet.png";
import Like from "./like.png";
import Time from "./time.png";
import Financing from "./financing.png";
import PrecioJustoImage from "./precio-justo.png";
import PeritajeIcon from "./peritajeIcon.png";
import { Link } from "react-router-dom";
import { routes } from "../../../../../routes/RoutesComponent";
import { useState } from "react";
import { Arrow } from "../../../../../assets/arrow";

export const DetailsList = ({ setShowPeritajeImage, warranty, car, description }) => {
    const [warrantyInfo, setWarrantyInfo] = useState(false);
    console.warn("car222", car);
    return (
        <>
            <div className="details_list">
                <div className="items_list">
                    {/* {car.type_warranty == "si" && (
                        <>
                            <p className="item price">
                                <img src={PrecioJustoImage} alt="wcar" />
                                Garantia de 7 dias
                            </p>
                            <hr />
                        </>
                    )} */}

                    {warranty && (
                        <>
                            <div
                                style={{ display: "flex", position: "relative", cursor: "pointer" }}
                                onClick={() => setWarrantyInfo(!warrantyInfo)}
                            >
                                <p>
                                    <img src={CarCheck} alt="wcar" /> Garantía de 6 meses
                                </p>
                                <div
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: "0",
                                    }}
                                >
                                    <div
                                        className="ms-2 claims_arrow"
                                        style={{
                                            transform: warrantyInfo ? "rotate(0deg)" : "rotate(180deg)",
                                        }}
                                    >
                                        <Arrow />
                                    </div>
                                </div>
                                <span
                                    className="warranty_tool_tip_detail position-absolute"
                                    style={{ display: warrantyInfo ? "block" : "none", marginTop: "10px" }}
                                >
                                    Garantía: Te ofrecemos una garantía máxima de seis (6) meses, o 4.000 kilómetros, lo
                                    que primero ocurra. No cubrimos piezas de desgaste ni daños ocasionados por mal
                                    manejo o falta de mantenimiento. Para conocer el detalle, ponte en contacto con un
                                    consejero.
                                </span>
                            </div>
                            <hr />
                        </>
                    )}
                    <a href="#financingBanner" className="item financing_text">
                        <img src={Financing} alt="wcar" /> Financiación hasta del 100% del vehiculo
                    </a>
                    <hr />
                    <p className="item price">
                        <img src={PrecioJustoImage} alt="wcar" />
                        Precio más justo&nbsp;<span> del mercado</span>
                    </p>
                    <hr />
                    {/* <a href="#financingBanner" className="item financing_text">
                        <img src={Financing} alt="wcar" />
                        Credi facil: <span style={{ marginLeft: "6px" }}> Financiación hasta&nbsp;del 100%</span>
                    </a>
                    <hr /> */}
                    <a href="#financingBanner" className="item financing_text">
                        <img src={Time} alt="wcar" />
                        Financia hasta $10 millones en 3 minutos
                    </a>
                    {car?.doors > 0 && parseInt(car?.tag?.id) !== 5 && parseInt(car?.year) > 2014 && (
                        <>
                            <hr />
                            <p className="item">
                                <img src={Like} alt="wcar" /> Garantía de felicidad: 7 días o te cambiamos el carro
                            </p>
                        </>
                    )}
                    {/* <hr /> */}
                    {/* <p onClick={() => setShowPeritajeImage(true)} style={{ cursor: "pointer", color: "#000" }}>
                        <img src={PeritajeIcon} alt="wcar" /> <strong>Peritaje de Colserauto Gratis</strong>
                    </p> */}
                </div>
            </div>
        </>
    );
};
